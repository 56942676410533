@import url("https://cdn.jsdelivr.net/npm/@exampledev/new.css@1.1.2/new.min.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/KaTeX/0.9.0/katex.min.css");
@import url("https://cdn.jsdelivr.net/gh/codex-src/iA-Fonts@master/iA%20Writer%20Mono/Webfonts/index.css");

:root {
  --nc-font-sans: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --nc-font-mono: "iA Writer Mono", "SF Mono", "Fira Code", Menlo, Consolas, monaco, "Ubuntu Mono", "Liberation Mono",
    "Courier New", Courier, monospace;

  /* Light theme */
  --nc-tx-1: #111111; // heading text
  --nc-tx-2: #2a2a2a; // body text
  --nc-bg-1: #f5f5f5; // base background color
  --nc-bg-2: #e6e8ea; // darker background color
  --nc-bg-3: #d5d7db; // slightly darker background color
  --nc-bg-4: #c9cbcf; // even darker
  --nc-lk-1: #0070f3; // action color
  --nc-lk-2: #0366d6; // action hover
  --nc-lk-tx: #ffffff; // text color for link buttons
  --nc-ac-1: #55ccff77; // accent background
  --nc-ac-tx: #0c4047; // text color in accent

  /* Dark theme */
  --nc-d-tx-1: #eeeeee; // heading text
  --nc-d-tx-2: #dddddd; // body text
  --nc-d-bg-1: #181818; // base background color
  --nc-d-bg-2: #222222; // darker background color
  --nc-d-bg-3: #333333; // slightly darker background color
  --nc-d-bg-4: #444444; // slightly darker background color
  --nc-d-lk-1: #3291ff; // action color
  --nc-d-lk-2: #0070f3; // action hover
  --nc-d-lk-tx: #ffffff; // text color for link buttons
  --nc-d-ac-1: #55ccff; // accent background
  --nc-d-ac-tx: #ffffff; // text color in accent

  /* Custom colors */
  --color-success: #11aa77;

  --color-alert: #ff4400;

  --color-subtitles: #888888;
  --color-d-subtitles: #777777;

  --color-code-block-color: #555555;
  --color-d-code-block-color: #bbbbbb;

  --color-light-gray: #aaaaaa;
  --color-d-light-gray: #555555;

  --color-hashtag-background: #ddffee;
  --color-hashtag-border: #c3eed8;
  --color-hashtag-color: #117766;
  --color-d-hashtag-background: #3b4942;
  --color-d-hashtag-border: #88dfd0;
  --color-d-hashtag-color: #88dfd0;

  --color-code-background: #fff8f8;
  --color-code-border: #ecd7d7;
  --color-code-color: #900;
  --color-d-code-background: rgb(83, 75, 75);
  --color-d-code-border: #ffc3c3;
  --color-d-code-color: #ffc3c3;

  --color-modal-background: #00000033;
  --color-d-modal-background: #000000aa;
  --color-box-shadow: #bbbbbb33;
  --color-d-box-shadow: #00000055;

  --color-event-event: #648fff;
  --color-event-event-hover: #648fffe5;
  --color-event-task: #ff9f06;
  --color-d-event-event: #648fff;
  --color-d-event-event-hover: #648fffe5;
  --color-d-event-task: #ff9f06;

  --color-event-color: #ffffff;
  --color-d-event-color: #111111;

  --color-badge: #55ccff;

  --color-search-backdrop: #00000033;
  --color-d-search-backdrop: #00000099;

  /* syntax highlighting */
  --syntax-comment: hsl(230, 4%, 64%);
  --syntax-operator: hsl(230, 8%, 24%);
  --syntax-url: hsl(119, 34%, 47%);
  --syntax-keyword: hsl(301, 63%, 40%);
  --syntax-variable: hsl(41, 99%, 30%);
  --syntax-regex: hsl(198, 99%, 37%);
  --syntax-number: hsl(41, 99%, 30%);
  --syntax-boolean: hsl(41, 99%, 30%);
  --syntax-tag: hsl(5, 74%, 59%);
  --syntax-constant: hsl(41, 99%, 30%);
  --syntax-symbol: hsl(198, 99%, 37%);
  --syntax-attr: hsl(41, 99%, 30%);
  --syntax-selector: hsl(301, 63%, 40%);
  --syntax-punctuation: hsl(230, 4%, 64%);
  --syntax-string: hsl(119, 34%, 47%);
  --syntax-char: hsl(198, 99%, 37%);
  --syntax-function: hsl(5, 74%, 59%);
  --syntax-class: hsl(5, 74%, 59%);

  --syntax-d-comment: #5c6370;
  --syntax-d-operator: #56b6c2;
  --syntax-d-url: #56b6c2;
  --syntax-d-keyword: #c678dd;
  --syntax-d-variable: #c678dd;
  --syntax-d-regex: #c678dd;
  --syntax-d-number: #d19a66;
  --syntax-d-boolean: #d19a66;
  --syntax-d-tag: #e06c75;
  --syntax-d-constant: #d19a66;
  --syntax-d-symbol: #d19a66;
  --syntax-d-attr: #d19a66;
  --syntax-d-selector: #e06c75;
  --syntax-d-punctuation: #abb2bf;
  --syntax-d-string: #98c379;
  --syntax-d-char: #98c379;
  --syntax-d-function: #61afef;
  --syntax-d-class: #c678dd;
}

@media (prefers-color-scheme: dark) {
  :root {
    --nc-tx-1: var(--nc-d-tx-1);
    --nc-tx-2: var(--nc-d-tx-2);
    --nc-bg-1: var(--nc-d-bg-1);
    --nc-bg-2: var(--nc-d-bg-2);
    --nc-bg-3: var(--nc-d-bg-3);
    --nc-bg-4: var(--nc-d-bg-4);
    --nc-lk-1: var(--nc-d-lk-1);
    --nc-lk-2: var(--nc-d-lk-2);
    --nc-lk-tx: var(--nc-d-lk-tx);
    --nc-ac-1: var(--nc-d-ac-1);
    --nc-ac-tx: var(--nc-d-ac-tx);

    --color-modal-background: var(--color-d-modal-background);
    --color-box-shadow: var(--color-d-box-shadow);
    --color-light-gray: var(--color-d-light-gray);
    --color-subtitles: var(--color-d-subtitles);
    --color-code-block-color: var(--color-d-code-block-color);
    --color-hashtag-background: var(--color-d-hashtag-background);
    --color-hashtag-border: var(--color-d-hashtag-border);
    --color-hashtag-color: var(--color-d-hashtag-color);
    --color-code-background: var(--color-d-code-background);
    --color-code-border: var(--color-d-code-border);
    --color-code-color: var(--color-d-code-color);
    --color-event-event: var(--color-d-event-event);
    --color-event-event-hover: var(--color-d-event-event-hover);
    --color-event-task: var(--color-d-event-task);
    --color-event-color: var(--color-d-event-color);
    --color-search-backdrop: var(--color-d-search-backdrop);

    --syntax-comment: var(--syntax-d-comment);
    --syntax-operator: var(--syntax-d-operator);
    --syntax-url: var(--syntax-d-url);
    --syntax-keyword: var(--syntax-d-keyword);
    --syntax-variable: var(--syntax-d-variable);
    --syntax-regex: var(--syntax-d-regex);
    --syntax-number: var(--syntax-d-number);
    --syntax-boolean: var(--syntax-d-boolean);
    --syntax-tag: var(--syntax-d-tag);
    --syntax-boolean: var(--syntax-d-boolean);
    --syntax-tag: var(--syntax-d-tag);
    --syntax-constant: var(--syntax-d-constant);
    --syntax-symbol: var(--syntax-d-symbol);
    --syntax-attr: var(--syntax-d-attr);
    --syntax-selector: var(--syntax-d-selector);
    --syntax-punctuation: var(--syntax-d-punctuation);
    --syntax-string: var(--syntax-d-string);
    --syntax-char: var(--syntax-d-char);
    --syntax-function: var(--syntax-d-function);
    --syntax-class: var(--syntax-d-class);
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  border-radius: 0;
  max-width: 100%;
  width: 100%;
  min-height: 100vh;
  padding: 0;
}

.centered {
  text-align: center;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: var(--nc-lk-1);

  svg {
    margin: 0;
    padding: 0;
    margin-right: 4px;
    width: 18px;
  }
}

.jumbo {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mono {
  font-family: var(--nc-font-mono);
}

.alert {
  position: absolute;
  top: 20px;
  background: var(--color-alert);
  color: var(--nc-lk-tx);
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 100px;
}

.success {
  position: absolute;
  top: 20px;
  background: var(--color-success);
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 100px;
  color: var(--nc-lk-tx);
}

.login,
.signup {
  @extend .jumbo;

  svg {
    margin-bottom: 20px;
  }

  .alert,
  .success {
    margin-bottom: 20px;
  }

  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;

    input {
      margin-bottom: 20px;
      width: 300px;
    }
  }
}

input {
  font-size: 1em;
}

.with-icon {
  display: flex;
  align-items: center;

  svg {
    padding: 0;
    margin: 0;
    margin-right: 8px;
  }
}

::-webkit-scrollbar {
  width: 2px;
  background: var(--nc-bg-2);
}

::-webkit-scrollbar-thumb {
  background: var(--nc-bg-3);
}

.page-not-found {
  @extend .jumbo;
}

.submit {
  background: var(--nc-lk-1) !important;
  color: var(--nc-lk-tx) !important;
  border: none !important;
  border-radius: 4px !important;

  &:hover {
    background: var(--nc-lk-2) !important;
  }
}

.strikethrough {
  text-decoration: line-through;
}

input[type="text"],
textarea,
button {
  outline: none;
}
